<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="대기방지시설명"
                  name="envAirMstPreventiveName"
                  v-model="data.envAirMstPreventiveName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="방지시설일련번호"
                  name="envAirMstPreventiveNo"
                  v-model="data.envAirMstPreventiveNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="사내고유방지시설번호"
                  name="envAirMstPreventiveNum"
                  v-model="data.envAirMstPreventiveNum">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-dept type="edit" label="관리부서" name="deptCd" v-model="data.deptCd" :isFirstValue="false" />
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="LBLLOCATION"
                  name="location"
                  v-model="data.location">
                </c-text>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-3">
                <c-text
                  suffix="㎥/분"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="처리용량"
                  name="handlingCapacity"
                  v-model="data.handlingCapacity">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-text
                  suffix="㎥/분"
                  type="number"
                  :editable="editable"
                  label="설계허가증상용량"
                  name="permitCapacity"
                  v-model="data.permitCapacity">
                </c-text>
              </div> -->
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="처리오염물질"
                  name="handlingMaterial"
                  v-model="data.handlingMaterial">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-text
                  type="number"
                  :editable="editable"
                  label="처리농도"
                  name="handlingConcentration"
                  v-model="data.handlingConcentration">
                </c-text>
              </div> -->
              <div class="col-3">
                <c-text
                  suffix="%"
                  type="number"
                  :editable="editable"
                  label="처리효율"
                  name="handlingPercent"
                  v-model="data.handlingPercent">
                </c-text>
              </div>
              <div class="col-6" v-show="param.envAirMstPreventiveId">
                <c-table
                  ref="grid2"
                  title="연결 배출시설"
                  tableId="grid2"
                  :columns="grid2.columns"
                  :data="grid2.data"
                  :usePaging="false"
                  :hideBottom="true"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :filtering="false"
                  :isExcelDown="false"
                  gridHeight="300px"
                  selection="multiple"
                  :editable="editable"
                  rowKey="envAirMstDischargeId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="LBLADD" v-if="editable && param.envAirMstPreventiveId" icon="add" @btnClicked="addrow2" />
                      <c-btn label="LBLREMOVE" v-if="editable && param.envAirMstPreventiveId" icon="remove" @btnClicked="removeRow2" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
          <!-- <c-card title="적산전력계" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="전력계부착여부"
                  name="wattmeterFlag"
                  v-model="data.wattmeterFlag"
                  @input="changeWattmeterFlag"
                />
              </div>
              <div class="col-2">
                <c-select
                  :required="data.wattmeterFlag=='Y'"
                  :editable="editable && data.wattmeterFlag=='Y'"
                  :comboItems="wattmeterItems"
                  label="적산전력계"
                  itemText="envAirMstWattmeterName"
                  itemValue="envAirMstWattmeterId"
                  name="envAirMstWattmeterId"
                  type="edit"
                  v-model="data.envAirMstWattmeterId"
                  @input="changeWattmeter">
                </c-select>
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="적산전력계번호"
                  name="envAirMstWattmeterNo"
                  v-model="data.envAirMstWattmeterNo">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="false"
                  label="배율"
                  type="number"
                  name="wattmeterMagnification"
                  v-model="data.wattmeterMagnification">
                </c-text>
              </div>
            </template>
          </c-card> -->
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master03-detail',
  props: {
    param: {
      type: Object,
      default(){
        return {
          envAirMstPreventiveId: '',
          plantCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirMstPreventiveId: '',  // 대기방지시설 시스템일련번호
        envAirMstPreventiveName: '',  // 대기방지시설명
        envAirMstPreventiveNo: '',  // 대기방지시설 일련번호
        envAirMstPreventiveNum: '',  // 대기방지시설 사내고유번호
        location: '',  // 위치
        handlingCapacity: '',  // 처리용량
        permitCapacity: '', // 설계허가증상용량
        handlingMaterial: '',  // 처리오염물질
        handlingConcentration: '',  // 처리농도
        handlingPercent: '',  // 처리효율
        wattmeterFlag: 'N',  // 전력계부착여부
        wattmeterMagnification: '',  //  배율
        envAirMstWattmeterId: '',  // 전력계일련번호
        envAirMstWattmeterNo: '',  // 전력계번호
        envAirMstWattmeterName: '',  // 전력계명
        deptCd: '',  // 관리부서 코드
        useFlag: 'Y',  // 사용여부
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      itemListUrl: '',
      itemInsertUrl: '',
      itemDeleteUrl: '',
      disListUrl: '',
      disInsertUrl: '',
      disDeleteUrl: '',
      saveUrl: transactionConfig.env.air.mst.preventive.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      wattmeterItems: [],
      grid2: {
        columns: [
          {
            name: 'envAirMstDischargeName',
            field: 'envAirMstDischargeName',
            label: '배출시설명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'envAirMstDischargeNo',
            field: 'envAirMstDischargeNo',
            label: '배출시설관리번호',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'handlingCapacity',
            field: 'handlingCapacity',
            label: '시설용량',
            align: 'right',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.mst.preventive.get.url;
      this.saveUrl = transactionConfig.env.air.mst.preventive.insert.url;
      this.disListUrl = selectConfig.env.air.mst.outlet.discharge.url;
      this.disInsertUrl = transactionConfig.env.air.mst.outlet.discharge.save.url;
      this.disDeleteUrl = transactionConfig.env.air.mst.outlet.discharge.delete.url;
      // this.getCombos();
      this.getDetail();
    },
    getCombos() {
      this.$http.url = selectConfig.env.air.mst.wattmeter.list.url;
      this.$http.param = {plantCd: this.param.plantCd, useFlag: 'Y'}
      this.$http.type = 'GET';
      this.$http.request((_watt) => {
        this.wattmeterItems = _watt.data;
        this.getDetail();
      },);
    },
    changeWattmeter() {
      let filterData = this.wattmeterItems.filter( x => {
        return x.envAirMstWattmeterId == this.data.envAirMstWattmeterId
      });
      this.data.envAirMstWattmeterNo = filterData[0].envAirMstWattmeterNo;
      this.data.wattmeterMagnification = filterData[0].wattmeterMagnification;
    },
    changeWattmeterFlag() {
      if(this.data.wattmeterFlag == 'N') {
        this.data.envAirMstWattmeterId = null;
        this.data.envAirMstWattmeterNo = '';
        this.data.wattmeterMagnification = 0;
      }
    },
    getDetail() {
      if (this.param.envAirMstPreventiveId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirMstPreventiveId: this.param.envAirMstPreventiveId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.getDisList();
        },);
      }
    },
    saveInfo() {
      if (this.param.envAirMstPreventiveId) {
        this.saveUrl = transactionConfig.env.air.mst.preventive.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.air.mst.preventive.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.envAirMstPreventiveId = result.data.envAirMstPreventiveId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertPreventiveInfo', result.data.envAirMstPreventiveId);
      }
      this.getDetail();
    },
    getDisList() {
      if (this.param.envAirMstPreventiveId) {
        this.$http.url = this.disListUrl;
        this.$http.param = {envAirMstPreventiveId: this.param.envAirMstPreventiveId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    addrow2() {
      this.popupOptions.title = '대기 배출시설';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./airMasterDischargePop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup2;
    },
    closePopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid2.data, { envAirMstDischargeId: item.envAirMstDischargeId }) === -1) {
            saveData.push({
              plantCd: item.plantCd,  // 사업장별 대기 검사항목 일련번호
              envAirMstPreventiveId: this.param.envAirMstPreventiveId,
              envAirMstDischargeId: item.envAirMstDischargeId,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
          }
        });
        this.$http.url = this.disInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getDisList();
        });
      }
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.disDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow2() {
      let checkItem = ['envAirMstOutletDischargeName']
      let isConti = true;
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [배출시설명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid2.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.disInsertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDisList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
  }
};
</script>